import React, { useState, useEffect, useCallback } from "react";
import styles from './Leagues.module.scss';

import {Table,Container,Row,Col,Button,Toast,Modal,Badge} from "react-bootstrap"
import Form from 'react-bootstrap/Form'
// import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import TeamPop from '../components/TeamPop3'
import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'

import loadable from '@loadable/component'
const BootstrapSwitchButton = loadable(() => import('bootstrap-switch-button-react'))

// const BootstrapSwitchButton = require("bootstrap-switch-button-react");

// const isBrowser = typeof window !== `undefined`

// componentDidMount(() => {
//   import BootstrapSwitchButton from 'bootstrap-switch-button-react'
// })

const axios = require("axios");

function LeagueInfo(props) {

  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col xs ={3}>
          <PageButton button_type="down" leagueDetails = {props.leagueDetails} gwPage={props.gwPage} setUrl={props.setUrl} newUrl={props.newUrl} />
        </Col>
        <Col xs={6}>
          <h1 className ={styles.gameweekh1}>
            {props.lgName}
          </h1>
        </Col>
        <Col xs={3}>
          <H2H leagueDetails = {props.leagueDetails} />
          <PageButton button_type="up" leagueDetails = {props.leagueDetails} gwPage={props.gwPage} setUrl={props.setUrl} newUrl={props.newUrl} />
        </Col>
      </Row>
    </Container>
  )
}

// function IC(props) {
//   if(props.leagueDetails.lg_code == 378509 || props.leagueDetails.lg_code == 378511){
//     return <Button
//         className ={styles.gameweekh1}
//         variant="dark"
//         href="../inner-circle/">
//             Inner Circle Menu
//             </Button>;
//   } else {
//   return null;
//   }
//   }

  function IC(props) {
    if(props.leagueDetails.lg_code == 378509 || props.leagueDetails.lg_code == 378511){
      return (<Row>
        <Col style={{marginTop:"-8px",marginBottom:"5px"}}>
        <Button
        variant="dark"
        block
        href="../inner-circle/">
            Inner Circle Menu
            </Button>
        </Col>
      </Row>);
    } else {
    return null;
    }
    }

function H2H(props) {
  // const hidePg = props.leagueDetails.npage === 0 ? "visible" :"hidden";

  if(props.leagueDetails.npage > 0 ){
  return null
} else{
return <Button
className ={styles.btnRight}
variant="dark"
href="h2h/">
    H2Hs <Badge variant="success">New</Badge>
    </Button>;
}
}

function PageButton(props) {
  const newPg = props.button_type === "up" ? props.leagueDetails.pg + 1 : props.leagueDetails.pg- 1;
  const dirPg = props.button_type === "up" ? ">>" : "<<";
  const hidePg = newPg === 0 ? "hidden" : props.button_type === "down" ? "visible" : props.leagueDetails.npage === 0 ? "hidden" :"visible";
  const btnClass = props.button_type === "up" ? styles.btnRight : styles.btnLeft;
  if(hidePg!= "visible" ){
    return null}
    else{
    return <Button
    className ={btnClass}
    variant="dark"
    size="lg"
    onClick={() => {
        console.log("startegt");
        props.setUrl(props.newUrl(props.leagueDetails.pid,props.leagueDetails.lg_code,props.leagueDetails.ord,newPg))}}
        style ={{visibility:hidePg}}>{dirPg}</Button>;
}
}

function LeagueLiveTable(props) {
  if(props.leagueDetails.pid===1){
  return (
    <Table
      key={props.leagueDetails.lg_code + '-' + props.leagueDetails.pid}
      hover
      size="sm"
      className={styles.leagues_table + " shadow " + styles.thin_td}
    >
      <thead className="thead-dark">
        <tr>
          <th></th>
          <th style={{textAlign:"left", verticalAlign:"middle"}}>Manager</th>
          <th style={{verticalAlign:"middle"}}>Captain</th>
          <th style={{verticalAlign:"middle"}}>Played<br/>/ Players</th>
          <th style={{verticalAlign:"middle"}}>Trans<br/>Value</th>
          <th style={{verticalAlign:"middle"}}>Wk Pts<br/><span className='font-italic text-secondary'>Proj</span></th>
          <th style={{verticalAlign:"middle"}}>Total Pts<br/><span className='font-italic text-secondary'>Proj</span></th>
        </tr>
      </thead>
      <tbody>
        {props.leagueLive.map(manager => {
          const tcost = manager.trans_cost > 0 ? " ("+manager.trans_cost * -1 + "pts)" : "";
          const trans = manager.trans >0 ? manager.trans : "";
          const wk_pts = props.leagueDetails.ord ===1 ? manager.wk_pts_live : manager.wk_pts_live_b;
          const t_pts = props.leagueDetails.ord ===1 ? manager.t_pts_live : manager.t_pts_live_b;
          // const chip = manager.chip === "wildcard" ? "WC" : manager.chip === "freehit" ? "FH" : manager.chip === "3xc" ? "TC" : manager.chip === "bboost" ? "BB" : "";
          const chip2 = manager.chip === "wildcard" ? "Wildcard"
          : manager.chip === "freehit" ? "Free Hit"
          : manager.chip === "3xc" ? "Triple Cap"
          : manager.chip === "bboost" ? "Bench Boost"
          : manager.chip === "manager" ? "Assistant Manager"
          : "";
          const val_a = manager.value / 10;
          const val = val_a.toFixed(1);
          const wk_proj = props.leagueDetails.proj_show ===1 ? "(" + (manager.live_proj_wk*1) +")": null;
          const tot_proj = props.leagueDetails.proj_show ===1 ? "(" + (manager.live_proj_total*1) +")": null;

          function testline(x) {
            if (x==0) {
              return null;
            }

            return (
              <br/>
            );
          }

           // onClick={props.handleShow()}
           // onClick={props.setManCode(manager.man_code)}
                       // <td>{"£" + val +"M"}</td>
                                 // <th>Chip</th>
                                   // <td>{chip}</td>
                                   //  style={{wordBreak: "break-all"}}

          return (
          <tr key={manager.indx} onClick={()=>props.handleShow(manager.man_code)} style={{cursor:"pointer", padding:"0",verticalAlign:"middle"}}>
            <td>{manager.rank2}</td>
            <td style={{textAlign:"left",textOverflow:"ellipsis",overflow:"hidden",maxWidth:"100px"}} >
              {manager.manager}
              <br/>
              <span className='font-italic text-secondary'>
              {manager.team_name}
              </span>
              <br/>
              <Badge pill variant="danger">
              {chip2}
              </Badge>
            </td>
            <td style={{textOverflow:"ellipsis",overflow:"hidden",maxWidth:"100px"}} >{manager.cap}</td>
            <td>{manager.s_players +"/"+manager.t_players}</td>
            <td>{trans + tcost}{testline(trans)}{"£"+val}</td>
            <td>{wk_pts}<br/><span className='font-italic text-secondary'>
            {wk_proj}
            </span></td>
            <td>{t_pts}<br/><span className='font-italic text-secondary'>
            {tot_proj}
            </span></td>
          </tr>
        )})}
      </tbody>
    </Table>
  );
} else {
  return null
}
}

function MonthTable(props) {
  if(props.leagueDetails.pid>1 && props.gwHist.length>0){
  // if(props.leagueDetails.pid>1){
  return (
    <Table
      key={props.leagueDetails.lg_code + '-' + props.leagueDetails.pid}
      hover
      size="sm"
      responsive
      className={styles.leagues_table + " shadow "+ styles.thin_td}
    >
      <thead className="thead-dark">
        <tr>
          <th></th>
          <th style={{textAlign:"left"}}>Manager</th>
          {props.gwHist[0].gameweeks.map(gw =>{
            return (
              <th key ={gw.gw}>{"GW" + gw.gw}</th>
            )
          })}
          <th>Month</th>
          <th>Trans</th>
          <th>Month<br/>Adj</th>
        </tr>
      </thead>
      <tbody>
      {props.gwHist.map(gwHist => {
        const details = gwHist.details;
        const gameweeks = gwHist.gameweeks;
        const wk1_tcost = gameweeks[0].trans_cost;
        const trans_cost_ph_v2 = details.trans_cost_ph>0 ? details.trans_cost_ph * -1 + " pts" : "";
        return (
        <tr key={details.man_code} style={{cursor:"pointer", padding:"0"}}>
          <td>{details.rank2}</td>
          <td style={{textAlign:"left"}} >
            {details.manager}
            <br/>
            <span className='font-italic text-secondary'>
            {details.team_name}
            </span>
          </td>
          {gameweeks.map(gw =>{
            return (
              <td key ={details.man_code + '-'+gw.gw}>{gw.wk_pts_live}</td>
            )
          })}
          <td>{details.tot_pts_ph *1 + wk1_tcost *1}</td>
          <td>{details.trans_ph}<br/>{trans_cost_ph_v2}</td>
          <td>{details.tot_pts_ph}</td>
        </tr>
      )})}
      </tbody>
    </Table>
  );
} else {
  return null
}
}

class TestSwitch extends React.Component {
// function TestSwitch(props) {
  constructor(props) {
      super(props);
      this.checkState = false;
      this.changecheckState = this.changecheckState.bind(this);
  }

  // const changecheckState = () => {
    changecheckState(){
    if(this.checkState===true)
      {this.neword = 1;
      this.checkState=false;}
      else
      {this.neword = 2;
      this.checkState=true;}
    this.props.setUrl(this.props.newUrl(this.props.leagueDetails.pid,this.props.leagueDetails.lg_code,this.neword,this.props.leagueDetails.pg))
    console.log(this.checkState)
  }
  render() {
    return  (
        <Col>
        <div className={styles.inputForm2}>
          <BootstrapSwitchButton
              onstyle="warning" style="border"
              checked = {this.checkState}
              onlabel = "Bonus Pts On"
              offlabel = "Bonus Pts Off"
              onChange={() => this.changecheckState()}
              width={150}

            />
        </div>
        </Col>
      );
}
}

class PhaseForm extends React.Component {
// function PhaseForm() {
constructor(props) {
    super(props);
    this.state = {value: '2'};
    // this.setUrl = this.props.setUrl;
    // this.newUrl = this.props.newUrl;
    this.handleChange = this.handleChange.bind(this);
}

  handleChange(event) {
    this.props.setUrl(this.props.newUrl(event.target.value,this.props.leagueDetails.lg_code,this.props.leagueDetails.ord,this.props.leagueDetails.pg));
    console.log(event.target.value);
  }

  render() {
    return  (
      <Col>
      <Form inline className={styles.inputForm}>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Control
            as="select" value={this.props.leagueDetails.pid}
            onChange={this.handleChange}
            >
            <option value='1'>This GW Live</option>
            <option value='2'>August</option>
            <option value='3'>September</option>
            <option value='4'>October</option>
            <option value='5'>November</option>
            <option value='6'>December</option>
            <option value='7'>January</option>
            <option value='8'>February</option>
            <option value='9'>March</option>
            <option value='10'>April</option>
            <option value='11'>May</option>
          </Form.Control>
        </Form.Group>
      </Form>
      </Col>
    );
}
}

var gwPage = 0;

var pg_get = 1;
var pid_get = 1;
var ord_get = 1;

var manCode = 0;

const useLeaguesState = (lgCode) => {
  // const [gw, setGW] = useState(0);
  const league_url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/league/"+pid_get+"/"+lgCode+"/"+ord_get+"/"+pg_get;
  const [url, setUrl] = useState(league_url)
  const [responseData, setResponseData] = useState([]);

  //PLAYER POP UP STATE
  const [show, setShow] = useState(false);
  const [manData, setManData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = async (newManCode) => {

    const manCode_url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/manager/"+newManCode
    console.log('fetch_url:'+manCode_url)
    const response = await axios.get(manCode_url);
    setManData(response.data);
    console.log("fetchran_man");
    setShow(true);
    };

    var manInfo = [];
    var manTeam  = [];
    var manTots = {};

    if (Object.keys(manData).length > 0) {
      manInfo = manData.info;
      manTeam = manData.manager_team;
      manTots = manData.manager_tots;
    }
//END

  const newUrl = (pid_x,lg_x,ord_x,pg_x) => {
      return "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/league/"+pid_x+"/"+lg_x+"/"+ord_x+"/"+pg_x;
}

  var leagueLive = [];
  var leagueDetails = [];
  var gwHist =[];

  if (Object.keys(responseData).length > 0) {

    leagueLive = responseData.league_live;
    gwHist = responseData.gw_hist;
    gwPage = responseData.details.gw;
    // leagueName = responseData.details.league;
    leagueDetails = responseData.details;
    console.log("gwPage:" + gwPage);
  }

  const fetchFpl = async (url) => {
    console.log('fetch_url:'+url)
    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");
    // setGW(response.data.status.gw);
  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 60000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

return {gwPage, leagueDetails, setUrl, newUrl, leagueLive,gwHist,show,handleShow,handleClose,manInfo,manTeam,manTots};
}

const Leagues = ({lgCode, lgName}) => {
  // console.log(lgCode);
  const {
    gwPage,
    leagueDetails,
    setUrl,
    newUrl,
    leagueLive,
    gwHist,
    show,
    handleShow,
    handleClose,
    manInfo,
    manTeam,
    manTots,
  } = useLeaguesState(lgCode);


  // const neword = (x) => {console.log("tetsttet" + x)}

          // onChange={setUrl(newUrl(leagueDetails.pid,leagueDetails.lg_code,2,leagueDetails.newPg))}
// onChange={setUrl('https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/league/1/15958/2/1')}
// <BootstrapSwitchButton checked={true} onstyle="success" />
// <BootstrapSwitchButton checked={true} onstyle="dark" offstyle="light" style="border"/>
// <BootstrapSwitchButton
//   onstyle="dark" offstyle="secondary" style="border" checked = {false}
// />
  return (
    <Container fluid >
      <Row>
        <Col xs={{span:12, order:2}} lg={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} lg={{span:6, order:2}} className={styles.App2}>
          <IC leagueDetails= {leagueDetails}/>
          <LeagueInfo lgName = {lgName} leagueDetails= {leagueDetails} gwPage={gwPage} setUrl={setUrl} newUrl = {newUrl}/>
          <TeamPop show= {show} handleClose = {handleClose} manInfo ={manInfo} manTeam={manTeam} manTots={manTots}/>
          <Container fluid className ={styles.gwInfo}>
            <Row>
              <PhaseForm leagueDetails= {leagueDetails} gwPage={gwPage} setUrl={setUrl} newUrl = {newUrl}/>
              <TestSwitch leagueDetails= {leagueDetails} gwPage={gwPage} setUrl={setUrl} newUrl = {newUrl}/>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <LeagueLiveTable leagueDetails= {leagueDetails} leagueLive = {leagueLive} handleShow = {handleShow}/>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <MonthTable leagueDetails= {leagueDetails} gwHist = {gwHist} />
            </Row>
          </Container>
          </Col>
          <Col xs={{span:12, order:3}} lg={{span:3, order:3}} style={{ marginRight: 0 }}>
          <SnackAd ad_type = "skyscraper" ad_num = {2}/>
          </Col>
        </Row>
      </Container>
  );
}

// <TestSwitch leagueDetails= {leagueDetails} gwPage={gwPage} setUrl={setUrl} newUrl = {newUrl}/>


export default Leagues;
